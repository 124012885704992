import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Toast, ToastBody, ToastHeader } from "reactstrap";

const TeamworkError = (props) => {
  const { peopleError, taskError, projectsError } = useSelector(
    (state) => state.teamworkDataReducer
  );
  let message = peopleError || taskError || projectsError;

  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);

  useEffect(() => {
    setShow(!!message);
  }, [message]);

  return !message ? null : (
    <div className="fixed-bottom p-3">
      <Toast className="ml-auto" isOpen={show}>
        <ToastHeader icon="danger" toggle={toggle}>
          Error
        </ToastHeader>
        <ToastBody>{message}</ToastBody>
      </Toast>
    </div>
  );
};

export default TeamworkError;
