import { COLORS } from "../constants";

const getStatusColor = (effort, total) => {
  const workMetric = (effort / total).toFixed(2);
  if (workMetric < 0.6) {
    return COLORS.yellow;
  } else if (workMetric >= 0.6 && workMetric <= 1.0) {
    return COLORS.green;
  } else {
    return COLORS.red;
  }
};

export default getStatusColor;

// Color Coding:
// Yellow = booked under 60%
// Green = booked 60 - 100%
// Red = booked above 100%
