export const LOCAL_HOST = "http://localhost:3000";
export const STAGING_URL_HOST = "https://teamwork-api-staging.sketch247.com";
export const TEAMWORK_API_HOST = "https://sketchfolio.teamwork.com";

export const WEEKS_LENGTH = 24;

export const TAGS = [
  "All tags",
  "Placeholder",
  "Reassign",
  "Placeholder (Sales)",
  "Vacation",
];

export const COLORS = {
  yellow: "#9B9B9B",
  red: "#b31b1b",
  green: "#44a03a",
};

export const HOURS_LEFT = {
  0: 40,
  1: 40,
  2: 32,
  3: 24,
  4: 16,
  5: 8,
  6: 0,
};

export const SORT_BY = [
  ["Due date", "Start date", "Date added", "Priority", "Project", "Company"],
  ["duedate", "startdate", "dateadded", "priority", "project", "company"],
];

export const INCLUDES = [
  ["No date", "No start date", "No due date", "Overdue", "Today"],
  ["nodate", "nostartdate", "noduedate", "overdue", "today"],
];

const CHECKBOX_VALUES = INCLUDES[1].reduce(
  (acc, curr) => ({ ...acc, [curr]: false }),
  {}
);
const FILTERED_CHECKBOX_VALUES = INCLUDES[1]
  .filter((i) => i !== "today")
  .reduce((acc, curr) => ({ ...acc, [curr]: false }), {});

export const TASK_RANGES = [
  [
    "All",
    "Anytime",
    "Overdue",
    "Today",
    "Tomorrow",
    "This week",
    "Within 7",
    "Within 14",
    "Within 30",
    "Within 365",
    "No Date",
    "No Start Date",
    "Completed",
  ],
  [
    "all",
    "anytime",
    "overdue",
    "today",
    "tomorrow",
    "thisweek",
    "within7",
    "within14",
    "within30",
    "within365",
    "nodate",
    "nostartdate",
    "completed",
  ],
  [
    CHECKBOX_VALUES,
    {},
    { nodate: false },
    FILTERED_CHECKBOX_VALUES,
    FILTERED_CHECKBOX_VALUES,
    FILTERED_CHECKBOX_VALUES,
    CHECKBOX_VALUES,
    CHECKBOX_VALUES,
    CHECKBOX_VALUES,
    CHECKBOX_VALUES,
    { overdue: false },
    {},
    {},
  ],
];

export const PARAMS = {
  filter: {
    name: "filter",
    label: TASK_RANGES[0],
    values: TASK_RANGES[1],
    alsoIncludes: TASK_RANGES[2],
  },
  include: {
    name: "include",
    label: INCLUDES[0],
    values: INCLUDES[1],
    alsoIncludes: TASK_RANGES[2],
  },
  "ignore-start-dates": { name: "ignore-start-dates" },
  sort: { name: "sort", label: SORT_BY[0], values: SORT_BY[1] },
  roles: ["All", "Designer", "Developer", "Project Manager"],
};
