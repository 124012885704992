import { Table } from "reactstrap";
import getStatusColor from "../../../utils/get-status-color";

const EmployeeProjectsTable = ({
  weeks,
  projectsData,
  projectsByName,
  timeLeft,
  name,
}) => {
  return (
    <Table hover responsive striped>
      <thead>
        <tr>
          <th className="table-left-header">Project</th>
          {weeks.map((week, i) => {
            return (
              <th className="table-header-week" key={i}>
                {week}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="table-left-header">Totals</td>
          {weeks.map((date, j) => {
            const hours =
              (projectsData[name][date] &&
                (projectsData[name][date].allHours / 60).toFixed(2)) ||
              0;
            return (
              <td
                style={{
                  backgroundColor: getStatusColor(
                    hours,
                    j === 0 ? timeLeft : 40
                  ),
                }}
                className="summary-hours"
                key={j}
              >
                {hours === 0 ? "-" : hours}
              </td>
            );
          })}
        </tr>
        {projectsByName[name].projects.map((project, k) => {
          const projectId = projectsByName[name]["project-ids"][project];
          return (
            <tr key={k}>
              <td>
                <a
                  href={`https://sketchfolio.teamwork.com/projects/${projectId}/tasks`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="table-left-header"
                >
                  {project}
                </a>
              </td>
              {weeks.map((date, k) => {
                const hours =
                  (projectsData[name][date] &&
                    projectsData[name][date][project] &&
                    (projectsData[name][date][project].allHours / 60).toFixed(
                      2
                    )) ||
                  0;
                return (
                  <td className="center summary-hours-black" key={k}>
                    {hours === 0 ? "-" : hours}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default EmployeeProjectsTable;
