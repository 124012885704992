import { FormGroup, Label, Input, Col } from "reactstrap";

export default function Ignore({
  ignoreStartDates,
  setIgnoreStartDates,
  dispatch,
}) {
  return (
    <>
      <Label>
        <b>Ignore</b>
      </Label>
      <FormGroup row>
        <Col sm={{ size: 10 }}>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                id="ignoreStartDates"
                name="ignoreStartDates"
                value={ignoreStartDates}
                checked={ignoreStartDates}
                onChange={(e) =>
                  dispatch(setIgnoreStartDates(e.target.checked))
                }
              />
              Start dates
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
    </>
  );
}
