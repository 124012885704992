const groupProjectsByName = (projectsData, weeks) => {
  const projectsByName =
    (projectsData &&
      Object.keys(projectsData).length > 0 &&
      Object.keys(projectsData).reduce((acc, curr) => {
        acc[curr] = { projects: [] };
        acc[curr]["project-ids"] = {};
        weeks.forEach((week) => {
          Object.keys(
            (projectsData[curr] && projectsData[curr][week]) || []
          ).forEach((project) => {
            acc[curr].projects.indexOf(project) === -1 &&
              !["allHours", "internalHours", "data"].includes(project) &&
              acc[curr].projects.push(project);
            if (projectsData[curr][week][project].data) {
              acc[curr]["project-ids"] = {
                ...acc[curr]["project-ids"],
                // TODO: use lodash or other lib to clean this and others like it
                [project]:
                  projectsData[curr][week][project].data[0]["project-id"],
              };
            }
          });
        });
        if (!!acc[curr].projects.length) {
          acc[curr].projects = acc[curr].projects.sort();
        }
        return acc;
      }, {})) ||
    {};
  return projectsByName;
};

export default groupProjectsByName;
