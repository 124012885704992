import { useState } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import get from "../../../utils/get";
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export default function ContributorsDelete({
  activeContributor,
  isOpen,
  refreshContributors,
  toggle,
  token,
}) {
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleError = (error) => {
    setError(
      (!!error.response &&
        !!error.response.data &&
        error.response.data.toString()) ||
        error.message
    );
    setSuccess(false);
    setInProgress(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setInProgress(true);

    try {
      const response = await get("/contributors/delete", "DELETE", token, {
        id: activeContributor.id,
      });

      if (response instanceof Error) {
        handleError(response);
      } else {
        setError(null);
        setSuccess(true);
        setInProgress(false);
        refreshContributors();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleToggle = () => {
    if (!inProgress) {
      toggle();
      setError(null);
      setSuccess(false);
    }
  };

  return !activeContributor ? null : (
    <Modal
      backdrop={inProgress ? "static" : true}
      isOpen={isOpen}
      toggle={handleToggle}
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleToggle}>Confirm Deletion</ModalHeader>
        <ModalBody>
          {!!error && (
            <Alert className="text-center" color="danger">
              {error}
            </Alert>
          )}
          {!!success && (
            <Alert className="text-center" color="success">
              Contributor {activeContributor.first_name}{" "}
              {activeContributor.last_name} deleted
            </Alert>
          )}
          {!success && (
            <>
              <p>
                Delete contributor for{" "}
                <strong>
                  {activeContributor.first_name} {activeContributor.last_name}
                </strong>
                .
              </p>
              <p>This action cannot be undone.</p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {!success && (
            <Button color="danger" disabled={inProgress} type="submit">
              {inProgress ? "Deleting…" : "Delete"}
            </Button>
          )}
          <Button
            color="secondary"
            disabled={inProgress}
            type="button"
            onClick={handleToggle}
          >
            {success ? "Close" : "Cancel"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

ContributorsDelete.propTypes = {
  activeContributor: shape({
    id: number,
    first_name: string,
    last_name: string,
    work_hours: number,
    roles: arrayOf(string),
  }),
  isOpen: bool,
  refreshContributors: func.isRequired,
  toggle: func.isRequired,
  token: string,
};
