const getBadgeColor = (tag) => {
  if (tag === "Meeting (Internal)" || tag === "Billing") return "#f4bd38";
  if (tag === "Meeting (External)" || tag === "Placeholder") return "#f78234";
  if (
    tag === "Waiting on Client" ||
    tag === "Waiting on Internal Response" ||
    tag === "Waiting on Vendor" ||
    tag === "Schedule"
  )
    return "#9e6957";
  if (
    tag === "Internal" ||
    tag === "Prep" ||
    tag === "Reassign" ||
    tag === "Maintenance"
  )
    return "#a6a6a6";
  if (tag === "Project Management" || tag === "Frontend") return "#4d4d4d";
  if (tag === "iPad" || tag === "Management") return "#9b7cdb";
  if (tag === "Sales" || tag === "ROADBLOCK") return "#d84640";
};

export default getBadgeColor;
