import {
  setIsLoading,
  setPassword,
  setIsAuth,
  setToken,
  setUserRole,
  setAuthError,
} from "../actions";
import get from "../utils/get";

function login(data = {}, timeout = 5000) {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await get("/login", "POST", null, data);
    dispatch(setIsLoading(false));
    if (response.data && response.data.token) {
      dispatch(setToken(response.data.token));
      dispatch(setIsAuth(true));
      dispatch(setPassword(""));
      dispatch(setAuthError(""));
      dispatch(setUserRole(response.data.role));
    } else if (response instanceof Error) {
      const errorMessage =
        (!!response.response &&
          !!response.response.data &&
          response.response.data.toString()) ||
        response.message;
      dispatch(setAuthError(errorMessage));
    }
  };
}

export default login;
