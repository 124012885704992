import { FormGroup, Label, CustomInput } from "reactstrap";

export default function Sort({ PARAMS, sort, setSort, dispatch }) {
  return (
    <FormGroup>
      <Label for="sort">
        <b>Sort</b>
      </Label>
      <CustomInput
        type="select"
        name={PARAMS.sort.name}
        id={PARAMS.sort.name}
        value={sort}
        onChange={(e) => {
          dispatch(setSort(e.target.value));
        }}
      >
        {PARAMS.sort.label.map((v, index) => (
          <option key={index} value={v.toLowerCase().replace(/\s/g, "")}>
            {v}
          </option>
        ))}
      </CustomInput>
    </FormGroup>
  );
}
