import { arrayOf, func, number, shape, string } from "prop-types";
import { getRoleLabel } from "../../../utils/role-options";
import { Button, Table } from "reactstrap";

export default function ContributorsTable({
  contributors = [],
  deleteContributor,
  editContributor,
}) {
  return !contributors.length ? null : (
    <Table hover responsive size="sm" striped>
      <thead>
        <tr>
          <th className="pl-3 table-left-header">Name</th>
          <th className="table-header-week">Work Hours</th>
          <th className="table-left-header">Roles</th>
          <th className="pr-2 table-left-header" style={{ width: 100 }}>
            <span className="pl-2">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {contributors.map((contributor) => (
          <tr key={`contributor-${contributor.id}`}>
            <td className="align-middle pl-3 table-left-header">
              {contributor.first_name} {contributor.last_name}
            </td>
            <td className="align-middle summary-hours-black">
              {contributor.work_hours}
            </td>
            <td className="align-middle table-left-header">
              {contributor.roles
                .map((role) => getRoleLabel(role))
                .sort()
                .join(", ")}
            </td>
            <td className="align-middle pr-2 table-left-header text-nowrap">
              <Button
                className="border-0 py-0"
                color="primary"
                onClick={() => editContributor(contributor)}
                outline
                size="sm"
                style={{ fontSize: "0.75rem" }}
                type="button"
              >
                Edit
              </Button>
              <Button
                className="border-0 py-0"
                color="danger"
                onClick={() => deleteContributor(contributor)}
                outline
                size="sm"
                style={{ fontSize: "0.75rem" }}
                type="button"
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

ContributorsTable.propTypes = {
  contributors: arrayOf(
    shape({
      id: number,
      first_name: string,
      last_name: string,
      work_hours: number,
      roles: arrayOf(string),
    })
  ),
  deleteContributor: func.isRequired,
  editContributor: func.isRequired,
};
