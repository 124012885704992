import { useEffect, useRef, useState } from "react";
import moment from "moment";
import groupProjectsByName from "../../../utils/group-projects-by-name";
import { Table } from "reactstrap";
import ProjectBreakdownRow from "./project-breakdown-row";
import { WEEKS_LENGTH } from "../../../constants";

export default function ProjectBreakdownTable({ projectsData = [] }) {
  const [projects, setProjects] = useState([]);
  const tableContainer = useRef();

  function getTableContainerHeight() {
    return !!tableContainer.current
      ? `calc(100vh - ${tableContainer.current.offsetTop}px)`
      : "100vh";
  }

  useEffect(() => {
    setProjects(getProjects(projectsData));
  }, [projectsData]);

  return (
    <div className="summary-table">
      <p className="mb-2">
        <strong>Project Breakdown</strong>
      </p>
      <div
        className="table-responsive mb-4"
        ref={tableContainer}
        style={{ height: getTableContainerHeight() }}
      >
        <Table className="mb-0" hover striped>
          <thead className="sticky-top bg-white">
            <tr>
              <th className="table-left-header">Project</th>
              {weeks.map((week) => (
                <th className="table-header-week" key={week}>
                  {week}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <ProjectBreakdownRow
                key={project.id}
                project={project}
                weeks={weeks}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

/*****/

const weeks = [];
for (let i = 0; i < WEEKS_LENGTH; i++) {
  const firstDayOfWeek = moment()
    .add(i * 7, "d")
    .startOf("week")
    .format("MM/DD/YYYY");
  weeks.push(firstDayOfWeek);
}

function getProjects(projectsData) {
  const projects = {};
  const employeeProjects = groupProjectsByName(projectsData, weeks);

  Object.entries(employeeProjects)
    .filter(([employeeName]) =>
      projectsData[employeeName].roles.includes("all")
    )
    .forEach(([employeeName, data]) => {
      data.projects.forEach((projectName) => {
        const projectId = data["project-ids"][projectName];
        const employeeEntry = {
          name: employeeName,
          totals: {},
        };

        if (!projects[projectId]) {
          projects[projectId] = {
            id: projectId,
            name: projectName,
            employees: [],
            totals: {},
            roles: {
              all: {
                name: "All",
                totals: {},
              },
              designer: {
                name: "Designer",
                totals: {},
              },
              developer: {
                name: "Developer",
                totals: {},
              },
              "project-manager": {
                name: "Project Manager",
                totals: {},
              },
              contractor: {
                name: "Contractor",
                totals: {},
              },
            },
          };
        }

        weeks.forEach((week) => {
          try {
            if (!projects[projectId].totals[week]) {
              projects[projectId].totals[week] = 0;
            }

            const total =
              projectsData[employeeName][week][projectName].allHours;
            projects[projectId].totals[week] += total;
            employeeEntry.totals[week] = total;

            projectsData[employeeName].roles.forEach((role) => {
              const lookupRole = role === "contractors+" ? "contractor" : role;

              if (!projects[projectId].roles[lookupRole].totals[week]) {
                projects[projectId].roles[lookupRole].totals[week] = total;
              } else {
                projects[projectId].roles[lookupRole].totals[week] += total;
              }
            });
          } catch (e) {}
        });

        projects[projectId].employees.push(employeeEntry);
      });
    });

  return Object.values(projects).sort((a, b) => (a.name > b.name ? 1 : -1));
}
