import { useState } from "react";
import { CustomInput } from "reactstrap";

export default function ProjectBreakdownRow({ project, weeks }) {
  const [display, setDisplay] = useState("summary");

  const handleDisplay = (e) => {
    setDisplay(e.target.value);
  };

  return (
    <>
      <tr className={display !== "summary" ? "bg-primary text-white" : ""}>
        <td className="table-left-header">
          <div className="d-flex align-items-center">
            <a
              className={display !== "summary" ? "text-white" : ""}
              href={`https://sketchfolio.teamwork.com/projects/${project.id}/tasks`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.name}
            </a>
            <div className="ml-auto pl-3">
              <CustomInput
                bsSize="sm"
                id="display"
                onChange={handleDisplay}
                type="select"
                value={display}
              >
                <option value="summary">–</option>
                <option value="person">Person</option>
                <option value="role">Role</option>
              </CustomInput>
            </div>
          </div>
        </td>
        {weeks.map((week) => (
          <td
            className="text-center summary-hours-black"
            key={`${project.id}-${week}`}
          >
            {formatTime(project.totals[week])}
          </td>
        ))}
      </tr>
      {display === "person" &&
        project.employees.map((employee) => (
          <tr className="table-primary" key={`${project.id}-${employee.name}`}>
            <td className="table-left-header pl-4">{employee.name}</td>
            {weeks.map((week) => (
              <td
                className="text-center summary-hours-black"
                key={`${project.id}-${employee.name}-${week}`}
              >
                {formatTime(employee.totals[week])}
              </td>
            ))}
          </tr>
        ))}
      {display === "role" &&
        Object.keys(project.roles)
          .filter((role) => role !== "all")
          .map((role) => (
            <tr className="table-primary" key={`${project.id}-${role}`}>
              <td className="table-left-header pl-4">
                {project.roles[role].name}
              </td>
              {weeks.map((week) => (
                <td
                  className="text-center summary-hours-black"
                  key={`${project.id}-${role}-${week}`}
                >
                  {formatTime(project.roles[role].totals[week])}
                </td>
              ))}
            </tr>
          ))}
    </>
  );
}

/*****/

function formatTime(time) {
  return !!time ? (time / 60).toFixed(2) : "-";
}
