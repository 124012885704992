import { useEffect } from "react";
import { Spinner, Container } from "reactstrap";
import getTeamworkProjectsData from "../../../thunks/get-teamwork-projects-data";
import useContributors from "../../../hooks/use-contributors";
import { useSelector, useDispatch } from "react-redux";
import SummaryTable from "./summary-table";
import EmployeeTables from "./employee-tables";
import FilterRoles from "./filter-roles";
import { setProjectRole, setCurrentPage } from "../../../actions";

export default function Projects(props) {
  const {
    isLoadingTeamworkProjectsData,
    projectsData,
    numProjectsResults,
    lastUpdatedProjects,
    peopleData,
  } = useSelector((state) => state.teamworkDataReducer);
  const projectRole = useSelector((state) => state.filtersReducer.projectRole);
  const contributors = useContributors();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!contributors.length && projectsData.length === 0) {
      dispatch(getTeamworkProjectsData(contributors));
    }
    dispatch(setCurrentPage("projects"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributors, projectsData]);
  return (
    <>
      <Container className="my-3" fluid>
        <FilterRoles
          projectRole={projectRole}
          setProjectRole={(role) => dispatch(setProjectRole(role))}
        />
      </Container>
      <div className="results">
        <Container fluid>
          Showing <b className="results-num">{numProjectsResults} results</b>{" "}
          from Teamwork API.{" "}
          {!!lastUpdatedProjects && (
            <span className="last-updated text-nowrap">
              (Last updated {lastUpdatedProjects})
            </span>
          )}
        </Container>
      </div>

      {isLoadingTeamworkProjectsData ? (
        <Spinner className="spinner-style" />
      ) : (
        <>
          <div style={{ backgroundColor: "#ffffff" }}>
            <SummaryTable
              data={projectsData}
              tableType="allHours"
              tableTitle="Weekly Summary"
              projectRole={projectRole}
            />
            <SummaryTable
              data={projectsData}
              tableType="internalHours"
              tableTitle="Summary (Internal)"
              projectRole={projectRole}
            />
            <EmployeeTables
              projectsData={projectsData}
              peopleData={peopleData}
              projectRole={projectRole}
            />
          </div>
        </>
      )}
    </>
  );
}
