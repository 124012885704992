import { useSelector, useDispatch } from "react-redux";
import { setUsername, setPassword } from "../../actions";
import Form from "../shared/form";
import login from "../../thunks/login";
import { Redirect, useLocation } from "react-router-dom";
import { Alert, Container, Row, Col } from "reactstrap";

const Login = () => {
  const dispatch = useDispatch();
  const { username, password, isLoading, isAuth, authError } = useSelector(
    (state) => state.userReducer
  );
  const { state: locationState } = useLocation();

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ username, password }, 5000));
  };

  return (
    <Container style={{ padding: 32 }}>
      {isAuth && <Redirect to={locationState?.from || "/workload"} />}
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          {!!authError && (
            <Alert className="px-md-5 text-center" color="danger">
              {authError}
            </Alert>
          )}
          <Form
            onSubmit={onSubmit}
            inputs={[
              {
                id: "username",
                label: "Username",
                type: "text",
                name: "username",
                onChange: (e) => dispatch(setUsername(e.target.value)),
              },
              {
                id: "password",
                label: "Password",
                type: "password",
                name: "password",
                onChange: (e) => dispatch(setPassword(e.target.value)),
              },
            ]}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
