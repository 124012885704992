import { Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap";

const TeamworkForm = ({ inputs, onSubmit, isLoading }) => {
  return (
    <Form onSubmit={onSubmit}>
      {inputs.map((input) => (
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0 form-input" key={input.id}>
          <Label for={input.id} className="mr-sm-2 form-label">
            {input.label}
          </Label>
          <Input
            type={input.type}
            name={input.name}
            id={input.id}
            onChange={input.onChange}
          />
        </FormGroup>
      ))}
      <Button style={{ marginTop: 15 }}>
        {isLoading ? <Spinner color="light" /> : "Submit"}
      </Button>
    </Form>
  );
};

export default TeamworkForm;
