import { useEffect } from "react";
import { Container, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import getTeamworkProjectsData from "../../../thunks/get-teamwork-projects-data";
import useContributors from "../../../hooks/use-contributors";
import { setCurrentPage } from "../../../actions";
import ProjectBreakdownTable from "./project-breakdown-table";

export default function ProjectBreakdown() {
  const {
    isLoadingTeamworkProjectsData,
    projectsData,
    numProjectsResults,
    lastUpdatedProjects,
  } = useSelector((state) => state.teamworkDataReducer);
  const contributors = useContributors();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!contributors.length && projectsData.length === 0) {
      dispatch(getTeamworkProjectsData(contributors));
    }
    dispatch(setCurrentPage("project-breakdown"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributors, projectsData]);

  return (
    <>
      <div className="results">
        <Container fluid>
          Showing <b className="results-num">{numProjectsResults} results</b>{" "}
          from Teamwork API.{" "}
          {!!lastUpdatedProjects && (
            <span className="last-updated text-nowrap">
              (Last updated {lastUpdatedProjects})
            </span>
          )}
        </Container>
      </div>

      {isLoadingTeamworkProjectsData ? (
        <Spinner className="spinner-style" />
      ) : (
        <div className="bg-white">
          <ProjectBreakdownTable projectsData={projectsData} />
        </div>
      )}
    </>
  );
}
