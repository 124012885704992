import { ACTION_TYPES } from "../actions";

const initialState = {
  contributors: [],
  contributorsLoading: false,
};

export default function contributorsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_CONTRIBUTORS:
      return {
        ...state,
        contributors: action.payload,
      };
    case ACTION_TYPES.SET_CONTRIBUTORS_LOADING:
      return {
        ...state,
        contributorsLoading: action.payload,
      };
    default:
      return state;
  }
}
