import { Component } from "react";
import { Col } from "reactstrap";
import DayPicker, { DateUtils } from "react-day-picker";
import moment from "moment";
import "react-day-picker/lib/style.css";
import "./calendar.css";

export default class Calendar extends Component {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  handleDayClick(day) {
    const { setStartDate, setEndDate } = this.props;
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    setStartDate(moment(range.from).format("YYYYMMDD"));
    setEndDate(moment(range.to).format("YYYYMMDD"));
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  static getDerivedStateFromProps(props, state) {
    if (state.from !== props.from && props.from === null) {
      return {
        from: undefined,
        to: undefined,
      };
    } else {
      return null;
    }
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className="RangeExample">
        <Col>
          <p>
            {!from && !to && "Please select the first day."}
            {from && !to && "Please select the last day."}
            {from &&
              to &&
              `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{" "}
          </p>
        </Col>
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
        />
      </div>
    );
  }
}
