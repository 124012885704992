import { setContributors, setContributorsLoading } from "../actions";
import get from "../utils/get";

export default function getContributors() {
  return async (dispatch, state) => {
    try {
      const { userReducer, contributorsReducer } = state();
      if (contributorsReducer.contributorsLoading) {
        return false;
      } else {
        dispatch(setContributorsLoading(true));
      }
      const response = await get(
        "/contributors/get",
        "GET",
        userReducer.token,
        null
      );
      if (response.data && Array.isArray(response.data)) {
        const contributors = response.data.map((contributor) => ({
          ...contributor,
          roles: !!contributor.roles ? JSON.parse(contributor.roles) : [],
        }));
        dispatch(setContributors(contributors));
        dispatch(setContributorsLoading(false));
      } else {
        console.log(response);
      }
    } catch (e) {
      console.error(e);
    }
  };
}
