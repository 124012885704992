import { ACTION_TYPES } from "../actions";

const initialState = {
  isLoading: false,
  isAuth: false,
  token: "",
  username: "",
  password: "",
  authError: "",
  users: [],
  usersLoading: false,
  userRole: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTION_TYPES.SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case ACTION_TYPES.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case ACTION_TYPES.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case ACTION_TYPES.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case ACTION_TYPES.SET_AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
      };
    case ACTION_TYPES.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ACTION_TYPES.SET_USERS_LOADING:
      return {
        ...state,
        usersLoading: action.payload,
      };
    case ACTION_TYPES.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    default:
      return state;
  }
}
