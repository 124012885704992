import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Button,
  Collapse,
  Media,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import logout from "../../thunks/logout";
import logo from "../../assets/logo.gif";
import GetDataButton from "./get-data-button";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { isAuth, token, userRole } = useSelector((state) => state.userReducer);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar expand="lg" id="top" light className="pr-lg-0">
      <Container className="px-0" fluid>
        <NavbarBrand
          className={isAuth ? "order-0 mr-auto mr-lg-3" : "mx-auto"}
          href="/"
        >
          <Media width="50" height="50" src={logo} alt="logo" />
        </NavbarBrand>
        {isAuth && (
          <>
            <NavbarToggler className="order-3" onClick={toggle} />
            <Collapse isOpen={isOpen} navbar className="order-4">
              <Nav navbar>
                <NavItem className="nav-item">
                  <NavLink tag={RouterNavLink} to="/workload">
                    By Task
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/projects">
                    By Project
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    activeClassName="active"
                    tag={RouterNavLink}
                    to="/planner"
                  >
                    Planner View
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    activeClassName="active"
                    tag={RouterNavLink}
                    to="/project-breakdown"
                  >
                    Project Breakdown
                  </NavLink>
                </NavItem>
                {userRole === "admin" && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Settings
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        activeClassName="active"
                        tag={RouterNavLink}
                        to="/users"
                      >
                        Users
                      </DropdownItem>
                      <DropdownItem
                        activeClassName="active"
                        tag={RouterNavLink}
                        to="/contributors"
                      >
                        Contributors
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                <NavItem>
                  <NavLink
                    color="link"
                    onClick={() => dispatch(logout(token))}
                    tag={Button}
                    type="button"
                  >
                    Log Out
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
            <GetDataButton className="refresh-button ml-3 ml-lg-auto order-2 order-lg-5" />
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default NavBar;
