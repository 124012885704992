import moment from "moment";

// dates between two dates https://stackoverflow.com/questions/23795522/how-to-enumerate-dates-between-two-dates-in-moment
function enumerateDaysBetweenDates(currDate, lastDate) {
  const dates = [];
  dates.push(currDate.format("MM/DD/YYYY"));

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().format("MM/DD/YYYY"));
  }
  dates.push(lastDate.format("MM/DD/YYYY"));

  return dates;
}

const formatPlannerData = (data, contributors = []) => {
  const projectData = data.reduce((acc, curr) => {
    const key = curr["responsible-party-names"];
    const creatorKey = `${curr["creator-firstname"]} ${curr["creator-lastname"]}`;
    const startDate = moment(curr["start-date"], "YYYYMMDD");
    const dueDate = moment(curr["due-date"], "YYYYMMDD");
    const dateRange = enumerateDaysBetweenDates(startDate, dueDate);
    if (dateRange.length === 0) {
      dateRange.push(dueDate.format("MM/DD/YYYY"));
    }
    const estimatedMinutes = curr["estimated-minutes"] || 0;
    const timePerDay = estimatedMinutes / dateRange.length;

    const projectName = curr["project-name"];
    dateRange.forEach((currDate) => {
      if (key in acc) {
        if (currDate in acc[key]) {
          const internalHours = curr["company-id"] === 62367 ? timePerDay : 0;
          acc[key][currDate].allHours += timePerDay || 0;
          acc[key][currDate].internalHours += internalHours;
          if (projectName in acc[key][currDate]) {
            acc[key][currDate][projectName].data.push(curr);
            acc[key][currDate][projectName].allHours += timePerDay || 0;
            acc[key][currDate][projectName].internalHours += internalHours;
          } else {
            acc[key][currDate][projectName] = {
              data: [curr],
              allHours: timePerDay || 0,
              internalHours: curr["company-id"] === 62367 ? timePerDay : 0,
              projectId: curr["project-id"],
            };
          }
        } else {
          acc[key][currDate] = {
            allHours: timePerDay || 0,
            internalHours: curr["company-id"] === 62367 ? timePerDay : 0,
          };
          acc[key][currDate][projectName] = {
            data: [curr],
            allHours: timePerDay || 0,
            internalHours: curr["company-id"] === 62367 ? timePerDay : 0,
            projectId: curr["project-id"],
          };
        }
      } else {
        const currEmployee = contributors.find(
          (e) =>
            !!key &&
            `${e["first_name"]} ${e["last_name"]}`.toLowerCase() ===
              key.toLowerCase()
        );
        const roles = currEmployee ? currEmployee.roles : [];
        acc[key] = {
          avatarUrl: "",
          roles: roles,
        };
        acc[key][currDate] = {
          allHours: timePerDay || 0,
          internalHours: curr["company-id"] === 62367 ? timePerDay : 0,
        };
        acc[key][currDate][projectName] = {
          data: [curr],
          allHours: timePerDay || 0,
          internalHours: curr["company-id"] === 62367 ? timePerDay : 0,
          projectId: curr["project-id"],
        };
      }
    });

    if (creatorKey in acc) {
      acc[creatorKey].avatarUrl = curr["creator-avatar-url"];
    }
    return acc;
  }, {});

  const sortedProjectData = {};
  Object.keys(projectData)
    .sort()
    .forEach(function (key) {
      sortedProjectData[key] = {};
      Object.keys(projectData[key])
        .sort((a, b) => moment(a, "MM/DD/YYYY") - moment(b, "MM/DD/YYYY"))
        .forEach(function (date) {
          sortedProjectData[key][date] = {};
          sortedProjectData[key][date].allHours = projectData[key].allHours;
          sortedProjectData[key][date].internalHours =
            projectData[key].internalHours;
          sortedProjectData[key].roles = projectData[key].roles;
          Object.keys(projectData[key][date])
            .sort()
            .sort(
              (a, b) =>
                projectData[key][date][a].allHours -
                projectData[key][date][b].allHours
            )
            .forEach(function (project) {
              sortedProjectData[key][date][project] =
                projectData[key][date][project];
            });
        });
      sortedProjectData[key].avatarUrl = projectData[key].avatarUrl;
    });
  return sortedProjectData;
};

export default formatPlannerData;
