import { Table, Row } from "reactstrap";
import moment from "moment";
import { slugify } from "../../../utils/slugify";
import { HashLink } from "react-router-hash-link";
import getStatusColor from "../../../utils/get-status-color";
import { HOURS_LEFT, WEEKS_LENGTH } from "../../../constants";

export default function SummaryTable({
  data,
  tableType,
  tableTitle,
  projectRole,
  type,
}) {
  const days = [];

  if (type === "planner") {
    for (let i = 0; i < WEEKS_LENGTH; i++) {
      const dayOfWeek = moment().add(i, "d").format("MM/DD/YYYY");
      days.push(dayOfWeek);
    }
  } else {
    for (let i = 0; i < WEEKS_LENGTH; i++) {
      const firstDayOfWeek = moment()
        .add(i * 7, "d")
        .startOf("week")
        .format("MM/DD/YYYY");
      days.push(firstDayOfWeek);
    }
  }
  const dayOfWeek = moment().day();
  const timeLeft = HOURS_LEFT[dayOfWeek];

  return (
    <div className="summary-table">
      <p className="mb-2">
        <strong>{tableTitle}</strong>
      </p>

      <Row
        style={{
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Table responsive>
          <thead>
            <tr>
              <th className="table-left-header">Name</th>
              {days.map((week, i) => {
                return (
                  <th className="table-header-week" key={i}>
                    {week}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((name, i) => {
              if (data[name].roles.includes(projectRole)) {
                return (
                  <tr key={i}>
                    <td>
                      {" "}
                      <HashLink
                        to={`/${type || "projects"}#${slugify(name)}`}
                        style={{ color: "black", textDecoration: "none" }}
                        className="table-left-header"
                      >
                        {name}
                      </HashLink>
                    </td>
                    {days.map((date, j) => {
                      const hours =
                        (data[name][date] &&
                          (data[name][date][tableType] / 60).toFixed(2)) ||
                        0;
                      if (j < 90) {
                        return (
                          <td
                            style={{
                              backgroundColor:
                                j === 0 && type !== "planner"
                                  ? getStatusColor(hours, timeLeft)
                                  : getStatusColor(
                                      hours,
                                      type === "planner" ? 8 : 40
                                    ),
                            }}
                            className="summary-hours"
                            key={j}
                          >
                            {hours === 0 || hours === "0.00" ? "-" : hours}
                          </td>
                        );
                      }
                      return null;
                    })}
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </Table>
      </Row>
    </div>
  );
}
