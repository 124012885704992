import { Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import {
  Table,
  Card,
  CardImg,
  Col,
  Row,
  Button,
  Container,
  Badge,
} from "reactstrap";
import moment from "moment";
import { slugify } from "../../../utils/slugify";
import { HOURS_LEFT } from "../../../constants";
import getStatusColor from "../../../utils/get-status-color";
import getBadgeColor from "../../../utils/get-badge-color";
import Warning from "./warning";
import getImg from "../../../utils/get-img";
import { COLORS } from "../../../constants";

export default function EmployeeTable({
  peopleData,
  employeeData,
  role,
  tag,
  bizDays,
}) {
  const dayOfWeek = moment().day();
  const timeLeft = HOURS_LEFT[dayOfWeek];
  return (
    peopleData &&
    employeeData &&
    Object.keys(employeeData).map((name, index) => {
      if (employeeData[name].roles.includes(role)) {
        let img = getImg(employeeData, peopleData, name);
        let allHours;
        if (tag !== "all-tags" && employeeData[name].tagHours) {
          allHours = (employeeData[name].tagHours[tag] / 60).toFixed(2);
        } else {
          allHours = (employeeData[name].allHours / 60).toFixed(2);
        }
        return (
          <Container className="table-container" key={index} fluid>
            <Card
              className="avatar-card"
              style={{
                backgroundColor: getStatusColor(
                  allHours,
                  bizDays * 8 || timeLeft
                ),
              }}
              id={slugify(name)}
            >
              <Row className="table-header flex-column flex-sm-row">
                <Col className="avatar-image-container">
                  <CardImg
                    src={img}
                    alt="Card image cap"
                    className="avatar-circle"
                  />
                </Col>
                <Col className="employee-table-name-and-hours">
                  <div>
                    <strong className="table-header-name">{name}</strong>
                  </div>
                  <div>Still active hours: {allHours}</div>
                </Col>
                <Col className="align-self-sm-center pt-3 pt-sm-0 text-right">
                  <Button
                    className="back-to-top-button"
                    tag={HashLink}
                    to="/workload#top"
                  >
                    Back to top
                  </Button>
                </Col>
              </Row>
            </Card>
            <Table className="mb-5" hover responsive striped>
              <thead>
                <tr>
                  <th className="employee-tasks-table">Due Date</th>
                  <th className="employee-tasks-table">&nbsp;</th>
                  <th className="employee-tasks-table">Project Name</th>
                  <th className="employee-tasks-table">Task List</th>
                  <th className="employee-tasks-table">Task</th>
                  <th className="employee-tasks-table">Effort</th>
                </tr>
              </thead>
              <tbody>
                {employeeData[name].data.map((e, j) => {
                  const priority = e["priority"];
                  const currTags =
                    e["tags"] && e["tags"].map((t) => slugify(t.name));
                  const isFilteredTag =
                    (e["tags"] &&
                      !currTags.includes("board-card") &&
                      currTags.includes(tag)) ||
                    (tag === "all-tags" &&
                      e["tags"] &&
                      !currTags.includes("board-card")) ||
                    (tag === "all-tags" && !e["tags"]);
                  if (isFilteredTag) {
                    return (
                      <tr key={j}>
                        <td
                          style={{ width: "100px" }}
                          className="employee-tasks-table"
                        >
                          <Row>
                            <Col style={{ paddingRight: "0px" }}>
                              {priority === "high" && (
                                <Warning fill={COLORS.red} />
                              )}
                              {priority === "medium" && (
                                <Warning fill="#dfd074" />
                              )}
                            </Col>
                            <Col>{moment(e["due-date"]).format("MM/DD")}</Col>
                          </Row>
                        </td>
                        <td className="employee-tasks-table">
                          {e["has-predecessors"] > 0 && (
                            <Badge style={{ backgroundColor: "#a6a6a6" }}>
                              Wait
                            </Badge>
                          )}
                        </td>
                        <td className="employee-tasks-table">
                          <a
                            href={`https://sketchfolio.teamwork.com/projects/${e["project-id"]}/tasks`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {e["project-name"]}
                          </a>
                        </td>
                        <td className="employee-tasks-table">
                          <a
                            href={`https://sketchfolio.teamwork.com/tasklists/${e["todo-list-id"]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {e["todo-list-name"]}
                          </a>
                        </td>
                        <td className="employee-tasks-table">
                          <a
                            href={`https://sketchfolio.teamwork.com/tasks/${e["id"]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {e["content"]}
                          </a>{" "}
                          {e["tags"] &&
                            e["tags"].map((t) => (
                              <Fragment key={t.id}>
                                <Badge
                                  style={{
                                    backgroundColor: getBadgeColor(t.name),
                                  }}
                                >
                                  {t.name}
                                </Badge>{" "}
                              </Fragment>
                            ))}
                        </td>
                        <td className="employee-tasks-table">
                          {(e["estimated-minutes"] / 60).toFixed(2)}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </Table>
          </Container>
        );
      }
      return null;
    })
  );
}
