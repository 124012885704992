import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import fetchUsers from "../../../thunks/fetch-users";
import { Button, Container } from "reactstrap";
import UsersDelete from "./users-delete";
import UsersForm from "./users-form";
import UsersTable from "./users-table";

export default function Users() {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const toggleDelete = () => {
    if (isDeleteOpen) {
      setActiveUser(null);
    }
    setIsDeleteOpen(!isDeleteOpen);
  };

  const deleteUser = (user) => {
    setActiveUser(user);
    toggleDelete();
  };

  const toggleForm = () => {
    if (isFormOpen) {
      setActiveUser(null);
    }
    setIsFormOpen(!isFormOpen);
  };

  const addUser = () => {
    setActiveUser(null);
    toggleForm();
  };

  const editUser = (user) => {
    setActiveUser(user);
    toggleForm();
  };

  const dispatch = useDispatch();
  const usersInit = useRef(false);
  const { token, username, users } = useSelector((state) => state.userReducer);

  const refreshUsers = () => dispatch(fetchUsers());

  useEffect(() => {
    if (!usersInit.current && (!users || !users.length)) {
      usersInit.current = true;
      dispatch(fetchUsers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <Container className="bg-white py-4" fluid>
      <header className="align-items-sm-center d-sm-flex mb-4">
        <h1 className="h2 mb-0">Users</h1>
        <Button
          className="ml-auto"
          color="primary"
          onClick={addUser}
          outline
          size="sm"
          type="button"
        >
          Add User
        </Button>
      </header>
      <UsersTable
        deleteUser={deleteUser}
        editUser={editUser}
        username={username}
        users={users}
      />
      <UsersDelete
        activeUser={activeUser}
        isOpen={isDeleteOpen}
        refreshUsers={refreshUsers}
        toggle={toggleDelete}
        token={token}
      />
      <UsersForm
        activeUser={activeUser}
        isOpen={isFormOpen}
        refreshUsers={refreshUsers}
        toggle={toggleForm}
        token={token}
        username={username}
      />
    </Container>
  );
}
