import { ACTION_TYPES } from "../actions";
import moment from "moment";

const initialState = {
  isLoadingTeamworkData: false,
  isLoadingTeamworkProjectsData: false,
  taskError: null,
  peopleData: [],
  peopleError: null,
  employeeData: null,
  projectsData: [],
  projectsError: null,
  plannerData: [],
  error: {},
  numResults: 0,
  numProjectsResults: 0,
  lastUpdated: null,
  lastUpdatedProjects: null,
};

export default function teamworkDataReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_TASK_ERROR:
      return {
        ...state,
        taskError: `${action.payload} [${moment().format("hh:mm:ss A")}]`,
      };
    case ACTION_TYPES.SET_PEOPLE_DATA:
      return {
        ...state,
        peopleData: action.payload,
        peopleError: initialState.peopleError,
      };
    case ACTION_TYPES.SET_PEOPLE_ERROR:
      return {
        ...state,
        peopleError: `${action.payload} [${moment().format("hh:mm:ss A")}]`,
      };
    case ACTION_TYPES.SET_IS_LOADING_TEAMWORK_DATA:
      return {
        ...state,
        isLoadingTeamworkData: action.payload,
      };
    case ACTION_TYPES.SET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload,
      };
    case ACTION_TYPES.SET_NUM_RESULTS:
      return {
        ...state,
        numResults: action.payload,
        lastUpdated: moment().format("hh:mm A"),
      };
    case ACTION_TYPES.SET_IS_LOADING_TEAMWORK_PROJECTS_DATA:
      return {
        ...state,
        isLoadingTeamworkProjectsData: action.payload,
      };
    case ACTION_TYPES.SET_TEAMWORK_PROJECTS_DATA:
      return {
        ...state,
        projectsData: action.payload,
        projectsError: initialState.projectsError,
      };
    case ACTION_TYPES.SET_TEAMWORK_PROJECTS_ERROR:
      return {
        ...state,
        projectsError: `${action.payload} [${moment().format("hh:mm:ss A")}]`,
      };
    case ACTION_TYPES.SET_NUM_PROJECTS_RESULTS:
      return {
        ...state,
        numProjectsResults: action.payload,
        lastUpdatedProjects: moment().format("hh:mm A"),
      };
    case ACTION_TYPES.SET_TEAMWORK_PLANNER_DATA:
      return {
        ...state,
        plannerData: action.payload,
        lastUpdated: moment().format("hh:mm A"),
      };
    default:
      return state;
  }
}
