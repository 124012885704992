export const roleOptions = [
  { label: "All", value: "all" },
  { label: "Contractors", value: "contractors" },
  { label: "Contractors+", value: "contractors+" },
  { label: "Designer", value: "designer" },
  { label: "Developer", value: "developer" },
  { label: "Project Manager", value: "project-manager" },
];

export const getRoleLabel = (value) => {
  const { label = null } =
    roleOptions.find((role) => role.value === value) || {};
  return label || value;
};
