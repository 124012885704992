import axios from "axios";
import { isDev } from "./is-dev";
import { LOCAL_HOST, STAGING_URL_HOST } from "../constants";

const get = async (path, method = "GET", token = null, data = {}) => {
  let response;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  };
  if (token) headers["Authorization"] = `Bearer ${token}`;
  try {
    response = await axios({
      url: `${isDev() ? LOCAL_HOST : STAGING_URL_HOST}${path}`,
      method: method,
      headers: headers,
      data: data,
      timeout: 5000,
    });
  } catch (e) {
    return e;
  }
  return response;
};

export default get;
