import { useEffect, useState } from "react";
import { Spinner, Container, Button } from "reactstrap";
import getTeamworkData from "../../../thunks/get-teamwork-data";
import { useSelector, useDispatch } from "react-redux";
import {
  setStartDate as setStart,
  setEndDate as setEnd,
  setTeamworkDataDefaults,
  setCurrentPage,
} from "../../../actions";
import useContributors from "../../../hooks/use-contributors";
import EmployeeCards from "./employee-cards";
import EmployeeTable from "./employee-table";
import Filter from "../../shared/filter";
import Calendar from "../../shared/calendar";

export default function Workload(props) {
  const [showFilter, setShowFilter] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const {
    isLoadingTeamworkData,
    peopleData,
    employeeData,
    numResults,
    lastUpdated,
  } = useSelector((state) => state.teamworkDataReducer);
  const { role, tag, startDate, bizDays } = useSelector(
    (state) => state.filtersReducer
  );
  const contributors = useContributors();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !!contributors.length &&
      (peopleData.length === 0 || !employeeData || employeeData.length === 0)
    ) {
      dispatch(getTeamworkData(contributors));
    }
    dispatch(setCurrentPage("workload"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributors, employeeData, peopleData]);

  function setStartDate(date) {
    dispatch(setStart(date));
  }

  function setEndDate(date) {
    dispatch(setEnd(date));
  }

  return (
    <>
      <Container className="py-3" fluid>
        {showFilter ? (
          <Button
            className="mr-3"
            onClick={() => {
              setShowFilter(false);
              setShowCalendar(false);
            }}
          >
            Close filters
          </Button>
        ) : (
          <Button
            className="mr-3"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            Show filters
          </Button>
        )}

        <span
          onClick={() => dispatch(setTeamworkDataDefaults())}
          className="small-action"
        >
          Reset to default
        </span>
        {showFilter && (
          <Filter
            showCalendar={showCalendar}
            setShowCalendar={setShowCalendar}
          />
        )}
        {showCalendar && (
          <Calendar
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            from={startDate}
          />
        )}
      </Container>
      <div className="results">
        <Container fluid>
          Showing <b className="results-num">{numResults} results</b> from
          Teamwork API.{" "}
          {!!lastUpdated && (
            <span className="last-updated text-nowrap">
              (Last updated {lastUpdated})
            </span>
          )}
        </Container>
      </div>
      {isLoadingTeamworkData ? (
        <Spinner className="spinner-style" />
      ) : (
        <div className="bg-white pt-3">
          <Container fluid>
            <div className="card-grid">
              <EmployeeCards
                employeeData={employeeData}
                peopleData={peopleData}
                role={role}
                tag={tag}
                bizDays={bizDays}
              />
            </div>
          </Container>
          <EmployeeTable
            peopleData={peopleData}
            employeeData={employeeData}
            role={role}
            tag={tag}
            bizDays={bizDays}
          />
        </div>
      )}
    </>
  );
}
