import { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import { useSelector } from "react-redux";
import useContributors from "../../../hooks/use-contributors";
import ContributorsDelete from "./contributors-delete";
import ContributorsForm from "./contributors-form";
import ContributorsTable from "./contributors-table";

export default function Contributors() {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [activeContributor, setActiveContributor] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const contributors = useContributors(refresh);
  const { token } = useSelector((state) => state.userReducer);

  const toggleDelete = () => {
    if (isDeleteOpen) {
      setActiveContributor(null);
    }
    setIsDeleteOpen(!isDeleteOpen);
  };

  const deleteContributor = (contributor) => {
    setActiveContributor(contributor);
    toggleDelete();
  };

  const toggleForm = () => {
    if (isFormOpen) {
      setActiveContributor(null);
    }
    setIsFormOpen(!isFormOpen);
  };

  const addContributor = () => {
    setActiveContributor(null);
    toggleForm();
  };

  const editContributor = (contributor) => {
    setActiveContributor(contributor);
    toggleForm();
  };

  const refreshContributors = () => setRefresh(true);

  useEffect(() => {
    setRefresh(false);
  }, [contributors]);

  return (
    <Container className="bg-white py-4" fluid>
      <header className="align-items-sm-center d-sm-flex mb-4">
        <h1 className="h2 mb-0">Contributors</h1>
        <Button
          className="ml-auto"
          color="primary"
          onClick={addContributor}
          outline
          size="sm"
          type="button"
        >
          Add Contributor
        </Button>
      </header>
      <ContributorsTable
        contributors={contributors}
        deleteContributor={deleteContributor}
        editContributor={editContributor}
      />
      <ContributorsDelete
        activeContributor={activeContributor}
        isOpen={isDeleteOpen}
        refreshContributors={refreshContributors}
        toggle={toggleDelete}
        token={token}
      />
      <ContributorsForm
        activeContributor={activeContributor}
        isOpen={isFormOpen}
        refreshContributors={refreshContributors}
        toggle={toggleForm}
        token={token}
      />
    </Container>
  );
}
