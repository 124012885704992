import { FormGroup, Label, Input, Col } from "reactstrap";
import { slugify } from "../../../utils/slugify";
import { TAGS } from "../../../constants";

export default function Tags({ tag, setTag, dispatch }) {
  return (
    <FormGroup tag="fieldset" row>
      <Col>
        <Label>
          <strong>Tags:</strong>
        </Label>
        {TAGS.map((radio) => {
          const radioSlug = slugify(radio);
          return (
            <FormGroup check key={radioSlug}>
              <Label check>
                <Input
                  type="radio"
                  name={radioSlug}
                  checked={radioSlug === tag}
                  onChange={(e) =>
                    dispatch(setTag(e.target.name.toLowerCase()))
                  }
                />
                {radio}
              </Label>
            </FormGroup>
          );
        })}
      </Col>
    </FormGroup>
  );
}
