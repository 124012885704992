import { arrayOf, func, number, shape, string } from "prop-types";
import { Button, Table } from "reactstrap";

export default function UsersTable({
  deleteUser,
  editUser,
  username,
  users = [],
}) {
  return !users.length ? null : (
    <Table hover responsive size="sm" striped>
      <thead>
        <tr>
          <th className="pl-3 table-left-header">Name</th>
          <th className="table-left-header">Email</th>
          <th className="table-left-header">Role</th>
          <th className="pr-3 table-left-header" style={{ width: 100 }}>
            <span className="pl-2">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={`user-${user.id}`}>
            <td className="align-middle pl-3 table-left-header">{user.name}</td>
            <td className="align-middle table-left-header">
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </td>
            <td className="align-middle table-left-header">{user.role}</td>
            <td className="align-middle pr-2 table-left-header text-nowrap">
              <Button
                className="border-0 py-0"
                color="primary"
                onClick={() => editUser(user)}
                outline
                size="sm"
                style={{ fontSize: "0.75rem" }}
                type="button"
              >
                Edit
              </Button>
              {username !== user.email && (
                <Button
                  className="border-0 py-0"
                  color="danger"
                  onClick={() => deleteUser(user)}
                  outline
                  size="sm"
                  style={{ fontSize: "0.75rem" }}
                  type="button"
                >
                  Delete
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

UsersTable.propTypes = {
  deleteUser: func.isRequired,
  editUser: func.isRequired,
  username: string,
  users: arrayOf(
    shape({
      id: number,
      name: string,
      email: string,
      role: string,
    })
  ),
};
