import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useContributors from "../../hooks/use-contributors";
import getTeamworkData from "../../thunks/get-teamwork-data";
import getTeamworkProjectsData from "../../thunks/get-teamwork-projects-data";
import { Button } from "reactstrap";

export default function GetDataButton(props) {
  const [inProgress, setInprogress] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshComplete, setRefreshComplete] = useState(false);
  const contributors = useContributors(refresh);
  const currentPage = useSelector((state) => state.filtersReducer.currentPage);
  const { isLoadingTeamworkData, isLoadingTeamworkProjectsData } = useSelector(
    (state) => state.teamworkDataReducer
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    setRefresh(true);
    setInprogress(true);
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setRefreshComplete(true);
    }
  }, [contributors, refresh]);

  useEffect(() => {
    if (refreshComplete) {
      if (currentPage === "workload") {
        dispatch(getTeamworkData(contributors));
      } else {
        dispatch(getTeamworkProjectsData(contributors));
      }
      setRefreshComplete(false);
      setInprogress(false);
    }
  }, [contributors, currentPage, dispatch, refreshComplete]);

  return (
    <Button
      {...props}
      disabled={
        inProgress || isLoadingTeamworkData || isLoadingTeamworkProjectsData
      }
      onClick={handleClick}
    >
      Get Data
    </Button>
  );
}
