import { Row, CardImg } from "reactstrap";
import moment from "moment";
import { slugify } from "../../../utils/slugify";
import getImg from "../../../utils/get-img";
import BarChart from "./BarChart";

export default function EmployeeProjectsBarChart({
  plannerData,
  peopleData,
  projectRole,
}) {
  const days = [];

  for (let i = 0; i < 10; i++) {
    const dayOfWeek = moment().add(i, "d").format("MM/DD/YYYY");
    days.push(dayOfWeek);
  }

  return (
    plannerData &&
    peopleData.length > 0 &&
    Object.keys(plannerData).map((name, i) => {
      if (plannerData[name].roles.includes(projectRole)) {
        let img = getImg(plannerData, peopleData, name);
        return (
          <div
            className="summary-table"
            style={{
              width: "100%",
              paddingTop: 15,
              paddingLeft: 30,
              paddingRight: 30,
              marginTop: 45,
              overflow: "unset",
            }}
            id={slugify(name)}
            key={i}
          >
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <div className="bar-chart">
                <div className="bar-chart-fr"></div>
                <div className="bar-chart-background-red"></div>
                <div className="bar-chart-background-green"></div>
                <div className="bar-chart-empty"></div>
                <div className="bar-chart-wrapper">
                  <div className="bar-chart-container">
                    <div className="bar-chart-first-column">
                      <div></div>
                      <CardImg
                        src={img}
                        alt="Card image cap"
                        className="avatar-circle"
                      />
                      <div>
                        <strong className="planner-name">
                          {name.split(" ")[0]}
                        </strong>
                      </div>
                    </div>
                    <BarChart
                      plannerData={plannerData}
                      name={name}
                      days={days}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </div>
        );
      }
      return null;
    })
  );
}
