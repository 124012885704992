import { FormGroup, Label, Input, Col } from "reactstrap";

export default function Includes({ PARAMS, include, setInclude, dispatch }) {
  return (
    <>
      <Label>
        <b>Also include</b>
      </Label>
      {Object.keys(include).map((v) => (
        <FormGroup key={v} row>
          <Col sm={{ size: 10 }}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id={v}
                  name={v}
                  value={include[v]}
                  checked={include[v]}
                  onChange={(e) =>
                    dispatch(
                      setInclude({
                        name: e.target.name,
                        value: e.target.checked,
                      })
                    )
                  }
                />
                {PARAMS.include.label[PARAMS.include.values.indexOf(v)]}
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
      ))}
    </>
  );
}
