import moment from "moment";

const formatProjectData = (data, contributors = []) => {
  const projectData = data.reduce((acc, curr) => {
    const key = curr["responsible-party-names"];
    const creatorKey = `${curr["creator-firstname"]} ${curr["creator-lastname"]}`;
    const firstDayOfWeek = moment(curr["due-date"], "YYYYMMDD")
      .startOf("week")
      .format("MM/DD/YYYY");
    const projectName = curr["project-name"];
    if (key in acc) {
      if (firstDayOfWeek in acc[key]) {
        const internalHours =
          curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0;
        acc[key][firstDayOfWeek].allHours += curr["estimated-minutes"] || 0;
        acc[key][firstDayOfWeek].internalHours += internalHours;
        if (projectName in acc[key][firstDayOfWeek]) {
          acc[key][firstDayOfWeek][projectName].data.push(curr);
          acc[key][firstDayOfWeek][projectName].allHours +=
            curr["estimated-minutes"] || 0;
          acc[key][firstDayOfWeek][projectName].internalHours += internalHours;
        } else {
          acc[key][firstDayOfWeek][projectName] = {
            data: [curr],
            allHours: curr["estimated-minutes"] || 0,
            internalHours:
              curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0,
          };
        }
      } else {
        acc[key][firstDayOfWeek] = {
          allHours: curr["estimated-minutes"] || 0,
          internalHours:
            curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0,
        };
        acc[key][firstDayOfWeek][projectName] = {
          data: [curr],
          allHours: curr["estimated-minutes"] || 0,
          internalHours:
            curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0,
        };
      }
    } else {
      const currEmployee = contributors.find(
        (e) =>
          !!key &&
          `${e["first_name"]} ${e["last_name"]}`.toLowerCase() ===
            key.toLowerCase()
      );
      const roles = currEmployee ? currEmployee.roles : [];
      acc[key] = {
        avatarUrl: "",
        roles: roles,
      };
      acc[key][firstDayOfWeek] = {
        allHours: curr["estimated-minutes"] || 0,
        internalHours:
          curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0,
      };
      acc[key][firstDayOfWeek][projectName] = {
        data: [curr],
        allHours: curr["estimated-minutes"] || 0,
        internalHours:
          curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0,
      };
    }
    if (creatorKey in acc) {
      acc[creatorKey].avatarUrl = curr["creator-avatar-url"];
    }
    return acc;
  }, {});

  const sortedProjectData = {};
  Object.keys(projectData)
    .sort()
    .forEach(function (key) {
      sortedProjectData[key] = {};
      Object.keys(projectData[key])
        .sort((a, b) => moment(a, "MM/DD/YYYY") - moment(b, "MM/DD/YYYY"))
        .forEach(function (date) {
          sortedProjectData[key][date] = {};
          sortedProjectData[key][date].allHours = projectData[key].allHours;
          sortedProjectData[key][date].internalHours =
            projectData[key].internalHours;
          sortedProjectData[key].roles = projectData[key].roles;
          Object.keys(projectData[key][date])
            .sort()
            .forEach(function (project) {
              sortedProjectData[key][date][project] =
                projectData[key][date][project];
            });
        });
      sortedProjectData[key].avatarUrl = projectData[key].avatarUrl;
    });
  return sortedProjectData;
};

export default formatProjectData;
