export const ACTION_TYPES = {
  SET_DATA: "SET_DATA",
  SET_USERNAME: "SET_USERNAME",
  SET_PASSWORD: "SET_PASSWORD",
  SET_IS_AUTH: "SET_IS_AUTH",
  SET_TOKEN: "SET_TOKEN",
  SET_AUTH_ERROR: "SET_AUTH_ERROR",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_TASK_ERROR: "SET_TASK_ERROR",
  SET_PEOPLE_DATA: "SET_PEOPLE_DATA",
  SET_PEOPLE_ERROR: "SET_PEOPLE_ERROR",
  SET_EMPLOYEE_DATA: "SET_EMPLOYEE_DATA",
  SET_IS_LOADING_TEAMWORK_DATA: "SET_IS_LOADING_TEAMWORK_DATA",
  SET_FILTER: "SET_FILTER",
  SET_IGNORE_START_DATES: "SET_IGNORE_START_DATES",
  SET_INCLUDE: "SET_INCLUDE",
  SET_INCLUDES: "SET_INCLUDES",
  SET_SORT: "SET_SORT",
  SET_ROLE: "SET_ROLE",
  SET_START_DATE: "SET_START_DATE",
  SET_END_DATE: "SET_END_DATE",
  SET_TEAMWORK_DATA_DEFAULTS: "SET_TEAMWORK_DATA_DEFAULTS",
  SET_NUM_RESULTS: "SET_NUM_RESULTS",
  SET_NUM_PROJECTS_RESULTS: "SET_NUM_PROJECTS_RESULTS",
  SET_TEAMWORK_PROJECTS_DATA: "SET_TEAMWORK_PROJECTS_DATA",
  SET_IS_LOADING_TEAMWORK_PROJECTS_DATA:
    "SET_IS_LOADING_TEAMWORK_PROJECTS_DATA",
  SET_TEAMWORK_PROJECTS_ERROR: "SET_TEAMWORK_PROJECTS_ERROR",
  SET_PROJECT_ROLE: "SET_PROJECT_ROLE",
  SET_TAG: "SET_TAG",
  SET_BIZ_DAYS: "SET_BIZ_DAYS",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_TEAMWORK_PLANNER_DATA: "SET_TEAMWORK_PLANNER_DATA",
  SET_USERS: "SET_USERS",
  SET_USERS_LOADING: "SET_USERS_LOADING",
  SET_USER_ROLE: "SET_USER_ROLE",
  SET_CONTRIBUTORS: "SET_CONTRIBUTORS",
  SET_CONTRIBUTORS_LOADING: "SET_CONTRIBUTORS_LOADING",
};

export const setEmployeeData = (data) => ({
  type: ACTION_TYPES.SET_EMPLOYEE_DATA,
  payload: data,
});

export const setTaskError = (error) => ({
  type: ACTION_TYPES.SET_TASK_ERROR,
  payload: `Could not get tasks from Teamwork (${error})`,
});

export const setPeopleData = (response) => ({
  type: ACTION_TYPES.SET_PEOPLE_DATA,
  payload: response,
});

export const setPeopleError = (error) => ({
  type: ACTION_TYPES.SET_PEOPLE_ERROR,
  payload: `Could not get people from Teamwork (${error})`,
});

export const setIsLoading = (loading) => ({
  type: ACTION_TYPES.SET_IS_LOADING,
  payload: loading,
});

export const setIsLoadingTeamworkData = (loading) => ({
  type: ACTION_TYPES.SET_IS_LOADING_TEAMWORK_DATA,
  payload: loading,
});

export const setData = (data) => ({
  type: ACTION_TYPES.SET_DATA,
  payload: data,
});

export const setUsername = (username) => ({
  type: ACTION_TYPES.SET_USERNAME,
  payload: username,
});

export const setPassword = (password) => ({
  type: ACTION_TYPES.SET_PASSWORD,
  payload: password,
});

export const setIsAuth = (isAuth) => ({
  type: ACTION_TYPES.SET_IS_AUTH,
  payload: isAuth,
});

export const setToken = (token) => ({
  type: ACTION_TYPES.SET_TOKEN,
  payload: token,
});

export const setAuthError = (error) => ({
  type: ACTION_TYPES.SET_AUTH_ERROR,
  payload: error,
});

export const setFilter = (filter) => ({
  type: ACTION_TYPES.SET_FILTER,
  payload: filter,
});

export const setIgnoreStartDates = (startDates) => ({
  type: ACTION_TYPES.SET_IGNORE_START_DATES,
  payload: startDates,
});

export const setInclude = (include) => ({
  type: ACTION_TYPES.SET_INCLUDE,
  payload: include,
});

export const setIncludes = (includes) => ({
  type: ACTION_TYPES.SET_INCLUDES,
  payload: includes,
});

export const setSort = (sort) => ({
  type: ACTION_TYPES.SET_SORT,
  payload: sort,
});

export const setRole = (role) => ({
  type: ACTION_TYPES.SET_ROLE,
  payload: role,
});

export const setStartDate = (date) => ({
  type: ACTION_TYPES.SET_START_DATE,
  payload: date,
});

export const setEndDate = (date) => ({
  type: ACTION_TYPES.SET_END_DATE,
  payload: date,
});

export const setTeamworkDataDefaults = () => ({
  type: ACTION_TYPES.SET_TEAMWORK_DATA_DEFAULTS,
});

export const setNumResults = (num) => ({
  type: ACTION_TYPES.SET_NUM_RESULTS,
  payload: num,
});

export const setNumProjectResults = (num) => ({
  type: ACTION_TYPES.SET_NUM_PROJECTS_RESULTS,
  payload: num,
});

export const setTeamworkProjectsData = (data) => ({
  type: ACTION_TYPES.SET_TEAMWORK_PROJECTS_DATA,
  payload: data,
});

export const setIsLoadingTeamworkProjectsData = (loading) => ({
  type: ACTION_TYPES.SET_IS_LOADING_TEAMWORK_PROJECTS_DATA,
  payload: loading,
});

export const setTeamworkProjectsError = (error) => ({
  type: ACTION_TYPES.SET_TEAMWORK_PROJECTS_ERROR,
  payload: `Could not get projects from Teamwork (${error})`,
});

export const setProjectRole = (role) => ({
  type: ACTION_TYPES.SET_PROJECT_ROLE,
  payload: role,
});

export const setTag = (tag) => ({
  type: ACTION_TYPES.SET_TAG,
  payload: tag,
});

export const setBizDays = (days) => ({
  type: ACTION_TYPES.SET_BIZ_DAYS,
  payload: days,
});

export const setCurrentPage = (page) => ({
  type: ACTION_TYPES.SET_CURRENT_PAGE,
  payload: page,
});

export const setTeamworkPlannerData = (data) => ({
  type: ACTION_TYPES.SET_TEAMWORK_PLANNER_DATA,
  payload: data,
});

export const setUsers = (users) => ({
  type: ACTION_TYPES.SET_USERS,
  payload: users,
});

export const setUsersLoading = (loading) => ({
  type: ACTION_TYPES.SET_USERS_LOADING,
  payload: loading,
});

export const setUserRole = (role) => ({
  type: ACTION_TYPES.SET_USER_ROLE,
  payload: role,
});

export const setContributors = (contributors) => ({
  type: ACTION_TYPES.SET_CONTRIBUTORS,
  payload: contributors,
});

export const setContributorsLoading = (loading) => ({
  type: ACTION_TYPES.SET_CONTRIBUTORS_LOADING,
  payload: loading,
});
