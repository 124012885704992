import { ACTION_TYPES } from "../actions";

const initialState = {
  filter: "thisweek",
  include: {
    nodate: false,
    nostartdate: false,
    noduedate: false,
    overdue: true,
    today: false,
  },
  previousInclude: {
    nodate: false,
    nostartdate: false,
    noduedate: false,
    overdue: true,
    today: false,
  },
  ignoreStartDates: true,
  sort: "duedate",
  role: "all",
  startDate: null,
  endDate: null,
  bizDays: null,
  projectRole: "all",
  tag: "all-tags",
  currentPage: "workload",
};

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case ACTION_TYPES.SET_INCLUDE:
      return {
        ...state,
        include: {
          ...state.include,
          [action.payload.name]: action.payload.value,
        },
        previousInclude: {
          ...state.previousInclude,
          [action.payload.name]: action.payload.value,
        },
      };
    case ACTION_TYPES.SET_INCLUDES:
      return {
        ...state,
        include: action.payload,
      };
    case ACTION_TYPES.SET_IGNORE_START_DATES:
      return {
        ...state,
        ignoreStartDates: action.payload,
      };
    case ACTION_TYPES.SET_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case ACTION_TYPES.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case ACTION_TYPES.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case ACTION_TYPES.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case ACTION_TYPES.SET_TEAMWORK_DATA_DEFAULTS:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_PROJECT_ROLE:
      return {
        ...state,
        projectRole: action.payload,
      };
    case ACTION_TYPES.SET_TAG:
      return {
        ...state,
        tag: action.payload,
      };
    case ACTION_TYPES.SET_BIZ_DAYS:
      return {
        ...state,
        bizDays: action.payload,
      };
    case ACTION_TYPES.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
}
