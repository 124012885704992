import { Form, FormGroup, Label, Input } from "reactstrap";
import { slugify } from "../../../utils/slugify";

const roles = [
  "All",
  "Designer",
  "Developer",
  "Project Manager",
  "Contractors",
  "Contractors+",
];

const FilterRoles = ({ setProjectRole, projectRole }) => {
  return (
    <Form>
      {roles.map((role, i) => {
        return (
          <FormGroup check inline key={i}>
            <Label check>
              <Input
                type="radio"
                name={slugify(role)}
                checked={slugify(role) === projectRole}
                onChange={(e) => setProjectRole(e.target.name)}
              />{" "}
              {role}
            </Label>
          </FormGroup>
        );
      })}
    </Form>
  );
};

export default FilterRoles;
