import { useEffect, useRef, useState } from "react";
import { bool, func, number, shape, string } from "prop-types";
import get from "../../../utils/get";
import {
  Alert,
  Button,
  CustomInput,
  Input,
  Label,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const initData = {
  id: "",
  name: "",
  email: "",
  role: "viewer",
  password: "",
};

const initFieldErrors = {
  name: null,
  email: null,
  role: null,
  password: null,
};

export default function UsersForm({
  activeUser,
  isOpen,
  refreshUsers,
  toggle,
  token,
  username,
}) {
  const [data, setData] = useState(initData);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState(initFieldErrors);
  const [inProgress, setInProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const form = useRef();

  const validateInput = (input) => {
    let message = null;

    if (input.validity.valueMissing) {
      message = "Required";
    } else if (input.validity.typeMismatch) {
      message = "Incorrect format";
    } else if (!input.validity.valid) {
      message = "Error";
    }

    setFieldErrors((prevErrors) => ({ ...prevErrors, [input.name]: message }));
  };

  const handleInput = (e) => {
    const input = e.target;
    setData((prevData) => ({ ...prevData, [input.name]: input.value }));
    validateInput(input);
  };

  const handleError = (error) => {
    setError(
      (!!error.response &&
        !!error.response.data &&
        error.response.data.toString()) ||
        error.message
    );
    setSuccess(false);
    setInProgress(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.current.checkValidity()) {
      setError("Check form for errors");
      [...form.current.elements].forEach((input) => {
        if (input.name) {
          validateInput(input);
        }
      });
      return false;
    }

    setInProgress(true);

    try {
      const request = !!activeUser
        ? { url: "/edit-user", method: "PUT" }
        : { url: "/create-user", method: "POST" };
      const response = await get(request.url, request.method, token, data);

      if (response instanceof Error) {
        handleError(response);
      } else {
        setError(null);
        setSuccess(true);
        setInProgress(false);
        refreshUsers();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleToggle = () => {
    if (!inProgress) {
      toggle();
      setData(initData);
      setError(null);
      setFieldErrors(initFieldErrors);
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (activeUser) {
      setData({ ...initData, ...activeUser });
    } else {
      setData(initData);
    }
  }, [activeUser]);

  return (
    <Modal
      backdrop={inProgress ? "static" : true}
      isOpen={isOpen}
      toggle={handleToggle}
    >
      <Form noValidate onSubmit={handleSubmit} innerRef={form}>
        <ModalHeader toggle={handleToggle}>
          {!!activeUser ? "Edit" : "Add"} User
        </ModalHeader>
        <ModalBody>
          {!!error && (
            <Alert className="text-center" color="danger">
              {error}
            </Alert>
          )}
          {!!success && (
            <Alert className="text-center" color="success">
              User {!!activeUser ? "updated" : "created"} for {data.name}
            </Alert>
          )}
          {!success && (
            <>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  invalid={!!fieldErrors.name}
                  onChange={handleInput}
                  required
                  value={data.name}
                />
                {!!fieldErrors.name && (
                  <FormFeedback>{fieldErrors.name}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  invalid={!!fieldErrors.email}
                  onChange={handleInput}
                  required
                  value={data.email}
                />
                {!!fieldErrors.email && (
                  <FormFeedback>{fieldErrors.email}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="role">Role</Label>
                <CustomInput
                  type="select"
                  name="role"
                  id="role"
                  disabled={!!activeUser && username === activeUser.email}
                  invalid={!!fieldErrors.role}
                  onChange={handleInput}
                  required
                  value={data.role}
                >
                  <option>viewer</option>
                  <option>admin</option>
                </CustomInput>
                {!!fieldErrors.role && (
                  <FormFeedback>{fieldErrors.role}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  autoCapitalize="off"
                  autoComplete="new-password"
                  autoCorrect="off"
                  type="password"
                  name="password"
                  id="password"
                  invalid={!!fieldErrors.password}
                  onChange={handleInput}
                  required={!activeUser}
                  spellCheck="false"
                  value={data.password}
                />
                {!!activeUser && (
                  <FormText>
                    <strong>Optional,</strong> leave empty if you don't want to
                    change the password
                  </FormText>
                )}
                {!!fieldErrors.password && (
                  <FormFeedback>{fieldErrors.password}</FormFeedback>
                )}
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {!success && (
            <Button color="primary" disabled={inProgress} type="submit">
              {inProgress ? "Saving…" : "Save"}
            </Button>
          )}
          <Button
            color="secondary"
            disabled={inProgress}
            type="button"
            onClick={handleToggle}
          >
            {success ? "Close" : "Cancel"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

UsersForm.propTypes = {
  activeUser: shape({
    id: number,
    name: string,
    email: string,
    role: string,
  }),
  isOpen: bool,
  refreshUsers: func.isRequired,
  toggle: func.isRequired,
  token: string,
  username: string,
};
