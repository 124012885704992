import {
  setIsLoadingTeamworkProjectsData,
  setTeamworkProjectsData,
  setPeopleData,
  setPeopleError,
  setNumProjectResults,
  setIsAuth,
  setToken,
  setTeamworkPlannerData,
  setTaskError,
} from "../actions";
import fetchAllPages from "../utils/fetch-all-pages";
import queryString from "query-string";
import formatProjectData from "../utils/format-projects-data";
import formatPlannerData from "../utils/format-planner-data";

function getTeamworkTasks(contributors = []) {
  return async (dispatch, state) => {
    if (!contributors.length) {
      return false;
    }
    const { userReducer, filtersReducer, teamworkDataReducer } = state();
    const { filter, include, ignoreStartDates, sort, startDate, endDate } =
      filtersReducer;
    const qpObj = {
      filter,
      include: Object.keys(include)
        .filter((key) => include[key])
        .join(","),
      "ignore-start-dates": ignoreStartDates,
      sort,
      startDate,
      endDate,
    };
    dispatch(setIsLoadingTeamworkProjectsData(true));
    let response;
    if (teamworkDataReducer.peopleData.length === 0) {
      const qpPeopleObj = qpObj;
      qpPeopleObj.filter = "all";
      const qpPeople = queryString.stringify(qpPeopleObj);
      try {
        response = await fetchAllPages(
          "people.json",
          `?${qpPeople}&responsible-party-ids=-1&page=`,
          1,
          userReducer.token,
          "people"
        );
      } catch (e) {
        console.log(e);
        dispatch(setIsLoadingTeamworkProjectsData(false));
        dispatch(setPeopleError(e.message));
        return e;
      }
      dispatch(setPeopleData(response));
    }
    const qpPeopleObj = qpObj;
    qpPeopleObj.filter = "all";
    try {
      response = await fetchAllPages(
        "tasks.json",
        `?filter=within365&include=overdue&page=`,
        1,
        userReducer.token,
        "todo-items"
      );
    } catch (e) {
      console.log(e);
      dispatch(setIsAuth(false));
      dispatch(setToken(""));
      dispatch(setIsLoadingTeamworkProjectsData(false));
      dispatch(setTaskError(e.message));
      return e;
    }
    dispatch(setNumProjectResults(response.length));
    // group data by name, week, and project name
    const sortedProjectData = formatProjectData(response, contributors);
    const sortedPlannerData = formatPlannerData(response, contributors);
    dispatch(setTeamworkProjectsData(sortedProjectData));
    dispatch(setTeamworkPlannerData(sortedPlannerData));
    dispatch(setIsLoadingTeamworkProjectsData(false));
  };
}

export default getTeamworkTasks;
