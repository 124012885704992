import { FormGroup, Label, Input, Col } from "reactstrap";
import { slugify } from "../../../utils/slugify";

export default function Roles({ PARAMS, role, setRole, dispatch }) {
  return (
    <FormGroup tag="fieldset" row>
      <Col>
        <Label>
          <strong>Roles:</strong>
        </Label>
        {PARAMS.roles.map((radio) => {
          const radioSlug = slugify(radio);
          return (
            <FormGroup check key={radioSlug}>
              <Label check>
                <Input
                  type="radio"
                  name={radioSlug}
                  checked={radioSlug === role}
                  onChange={(e) =>
                    dispatch(setRole(e.target.name.toLowerCase()))
                  }
                />
                {radio}
              </Label>
            </FormGroup>
          );
        })}
      </Col>
    </FormGroup>
  );
}
