import { Row, Col, Card, CardImg, Button } from "reactstrap";
import moment from "moment";
import { HashLink } from "react-router-hash-link";
import { slugify } from "../../../utils/slugify";
import { HOURS_LEFT, WEEKS_LENGTH } from "../../../constants";
import groupProjectsByName from "../../../utils/group-projects-by-name";
import EmployeeProjectsTable from "./employee-projects-table";
import getImg from "../../../utils/get-img";

export default function EmployeeTables({
  projectsData,
  peopleData,
  projectRole,
}) {
  const weeks = [];
  for (let i = 0; i < WEEKS_LENGTH; i++) {
    const firstDayOfWeek = moment()
      .add(i * 7, "d")
      .startOf("week")
      .format("MM/DD/YYYY");
    weeks.push(firstDayOfWeek);
  }

  const projectsByName = groupProjectsByName(projectsData, weeks);

  return (
    projectsData &&
    peopleData.length > 0 &&
    Object.keys(projectsData).map((name, i) => {
      if (projectsData[name].roles.includes(projectRole)) {
        let img = getImg(projectsData, peopleData, name);
        const dayOfWeek = moment().day();
        const timeLeft = HOURS_LEFT[dayOfWeek];
        return (
          <div
            className="summary-table pt-3 px-sm-4 mt-5"
            id={slugify(name)}
            key={i}
          >
            <Card className="avatar-card-projects px-0 px-sm-4">
              <Row className="table-header flex-column flex-sm-row">
                <Col className="avatar-image-container">
                  <CardImg
                    src={img}
                    alt="Card image cap"
                    className="avatar-circle"
                  />
                </Col>
                <Col className="align-self-sm-center">
                  <strong className="table-header-name">{name}</strong>
                </Col>
                <Col className="align-self-sm-center pt-3 pt-sm-0 text-right">
                  <Button
                    className="back-to-top-button"
                    tag={HashLink}
                    to="/projects#top"
                  >
                    Back to top
                  </Button>
                </Col>
              </Row>
            </Card>

            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <EmployeeProjectsTable
                weeks={weeks}
                projectsData={projectsData}
                projectsByName={projectsByName}
                timeLeft={timeLeft}
                name={name}
              />
            </Row>
          </div>
        );
      }
      return null;
    })
  );
}
