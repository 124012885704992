import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Login from "./views/login";
import Workload from "./views/workload/index.js";
import Projects from "./views/projects/index.js";
import Planner from "./views/planner/index.js";
import ProjectBreakdown from "./views/project-breakdown/index.js";
import Users from "./views/users/index.js";
import Contributors from "./views/contributors/index.js";
import NavBar from "./shared/navbar";
import TeamworkError from "./shared/error";

function PrivateRoute({ disallowed, isAuth, ...props }) {
  if (!isAuth) {
    delete props.component;
    props.render = ({ location }) => (
      <Redirect to={{ pathname: "/login", state: { from: location } }} />
    );
  } else if (disallowed) {
    delete props.component;
    props.render = (props) => <Redirect to="/workload" />;
  }

  return <Route {...props} />;
}

function Root() {
  const { isAuth, userRole } = useSelector((state) => state.userReducer);

  return (
    <Router>
      <NavBar />
      <Switch>
        <PrivateRoute
          exact
          path="/workload"
          component={Workload}
          isAuth={isAuth}
        />
        <PrivateRoute
          exact
          path="/projects"
          component={Projects}
          isAuth={isAuth}
        />
        <PrivateRoute
          exact
          path="/planner"
          component={Planner}
          isAuth={isAuth}
        />
        <PrivateRoute
          exact
          path="/project-breakdown"
          component={ProjectBreakdown}
          isAuth={isAuth}
        />
        <PrivateRoute
          exact
          path="/users"
          component={Users}
          isAuth={isAuth}
          disallowed={userRole !== "admin"}
        />
        <PrivateRoute
          exact
          path="/contributors"
          component={Contributors}
          isAuth={isAuth}
          disallowed={userRole !== "admin"}
        />
        <Route exact path="/login" component={Login} />
        <Route path="/">
          {!isAuth ? <Redirect to="/login" /> : <Redirect to="/workload" />}
        </Route>
      </Switch>
      {!isAuth && <TeamworkError />}
    </Router>
  );
}

export default Root;
