import axios from "axios";
import { STAGING_URL_HOST, LOCAL_HOST } from "../constants";
import { isDev } from "./is-dev";

async function fetchAllPages(path, params, page, token, key) {
  const data = [];
  const config = {
    method: "get",
    url: `${
      isDev() ? LOCAL_HOST : STAGING_URL_HOST
    }/teamwork/data/${path}${params}${page}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    timeout: 30000,
  };
  do {
    let response = await axios(config);
    if (response.data[key].length > 0) {
      page = page + 1;
      config.url = `${
        isDev() ? LOCAL_HOST : STAGING_URL_HOST
      }/teamwork/data/${path}${params}${page}`;
    } else {
      config.url = false;
    }
    data.push(...response.data[key]);
  } while (config.url);

  return data;
}

export default fetchAllPages;
