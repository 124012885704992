import { setUsers, setUsersLoading } from "../actions";
import get from "../utils/get";

export default function fetchUsers() {
  return async (dispatch, state) => {
    try {
      const { userReducer } = state();
      if (userReducer.usersLoading) {
        return false;
      } else {
        dispatch(setUsersLoading(true));
      }
      const response = await get(
        "/fetch-users",
        "GET",
        userReducer.token,
        null
      );
      if (response.data && Array.isArray(response.data)) {
        dispatch(setUsers(response.data));
        dispatch(setUsersLoading(false));
      } else {
        console.log(response);
      }
    } catch (e) {
      console.error(e);
    }
  };
}
