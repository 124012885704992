import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import getContributors from "../thunks/get-contributors";

export default function useContributors(refresh) {
  const dispatch = useDispatch();
  const { contributors = [] } = useSelector(
    (state) => state.contributorsReducer
  );
  const [shouldGet, setShouldGet] = useState(refresh || !contributors.length);

  useEffect(() => {
    if (refresh) {
      setShouldGet(true);
    }
  }, [refresh]);

  useEffect(() => {
    if (shouldGet) {
      setShouldGet(false);
      dispatch(getContributors());
    }
  }, [dispatch, shouldGet]);

  return contributors || [];
}
