import { useSelector, useDispatch } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import { PARAMS } from "../../../constants";
import {
  setFilter,
  setInclude,
  setIncludes,
  setIgnoreStartDates,
  setSort,
  setRole,
  setTag,
} from "../../../actions";
import Includes from "./includes";
import Filter from "./filter";
import Ignore from "./ignore";
import Sort from "./sort";
import Roles from "./roles";
import Tags from "./tags";

export default function Filters({ showCalendar, setShowCalendar }) {
  const dispatch = useDispatch();
  const {
    filter,
    include,
    previousInclude,
    ignoreStartDates,
    sort,
    role,
    tag,
  } = useSelector((state) => state.filtersReducer);
  return (
    <Container className="filters">
      <Row>
        <Col md={2} className="filter-container">
          <Filter
            PARAMS={PARAMS}
            filter={filter}
            previousInclude={previousInclude}
            setFilter={setFilter}
            setIncludes={setIncludes}
            dispatch={dispatch}
            showCalendar={showCalendar}
            setShowCalendar={setShowCalendar}
          />
        </Col>
        <Col md={2} className="filter-container">
          <Includes
            PARAMS={PARAMS}
            include={include}
            setInclude={setInclude}
            dispatch={dispatch}
          />
        </Col>
        <Col md={2} className="filter-container">
          <Ignore
            ignoreStartDates={ignoreStartDates}
            setIgnoreStartDates={setIgnoreStartDates}
            dispatch={dispatch}
          />
        </Col>
        <Col md={2} className="filter-container">
          <Sort
            PARAMS={PARAMS}
            sort={sort}
            setSort={setSort}
            dispatch={dispatch}
          />
        </Col>
        <Col md={2} className="filter-container">
          <Roles
            PARAMS={PARAMS}
            role={role}
            setRole={setRole}
            dispatch={dispatch}
          />
        </Col>
        <Col md={2}>
          <Tags tag={tag} setTag={setTag} dispatch={dispatch} />
        </Col>
      </Row>
    </Container>
  );
}
