import { Fragment } from "react";
import getStatusColor from "../../../utils/get-status-color";
import moment from "moment";

const BarChart = ({ plannerData, name, days }) => {
  const pixelHeightPerHour = 60;
  // console.log(plannerData);
  return days.map((date, i) => {
    let color;
    let allhours;
    if (plannerData[name] && plannerData[name][date]) {
      allhours = (plannerData[name][date].allHours / 60).toFixed(2);
      color = getStatusColor(allhours, 8);
    }
    return (
      <div className="column" key={i}>
        {allhours && (
          <div className="column-total-hours" style={{ color: color }}>
            {allhours}h
          </div>
        )}
        {plannerData[name][date] &&
          Object.keys(plannerData[name][date]).map((project, j) => {
            // console.log(name, date, project, plannerData[name][date][project]);
            if (!["allHours", "internalHours"].includes(project)) {
              const hours = (
                plannerData[name][date][project].allHours / 60
              ).toFixed(2);

              return (
                <Fragment key={j}>
                  <a
                    href={`https://sketchfolio.teamwork.com/projects/${plannerData[name][date][project].projectId}/tasks`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="column-link"
                    title={project}
                  >
                    <div
                      className="column-name-plus-hours"
                      style={{
                        backgroundColor: color,
                        whiteSpace: hours < 1 && "nowrap",
                      }}
                      key={j}
                    >
                      <div
                        className="column-project-name"
                        style={{ height: `${hours * pixelHeightPerHour}px` }}
                      >
                        {project}
                      </div>
                      <div className="column-hours">{hours}h</div>
                    </div>
                  </a>
                </Fragment>
              );
            } else {
              return null;
            }
          })}
        <div className="column-day pt-2">{moment(date, "MM/DD/YYYY").format("dddd")}</div>
        <div className="column-date">{date}</div>
      </div>
    );
  });
};

export default BarChart;
