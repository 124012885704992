import {
  setIsAuth,
  setToken,
  setUsers,
  setUserRole,
  setUsername,
  setContributors,
} from "../actions";
import get from "../utils/get";

function logout(token) {
  return async (dispatch) => {
    get("/logout", "POST", token, {});
    dispatch(setIsAuth(false));
    dispatch(setToken(""));
    dispatch(setUsername(""));
    dispatch(setUsers([]));
    dispatch(setUserRole(null));
    dispatch(setContributors([]));
    sessionStorage.clear();
    localStorage.clear();
  };
}

export default logout;
