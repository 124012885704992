import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import storage from "redux-persist/lib/storage";
import teamworkDataReducer from "./teamwork-data-reducer";
import filtersReducer from "./filters-reducer";
import contributorsReducer from "./contributors-reducer";

const userReducerConfig = {
  key: "userReducer",
  storage,
  blacklist: ["isLoading", "password", "authError", "users"],
};

export default combineReducers({
  userReducer: persistReducer(userReducerConfig, userReducer),
  teamworkDataReducer: teamworkDataReducer,
  filtersReducer: filtersReducer,
  contributorsReducer: contributorsReducer,
});
