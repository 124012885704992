import { FormGroup, Label, CustomInput } from "reactstrap";

export default function Filter({
  PARAMS,
  filter,
  previousInclude,
  setFilter,
  setIncludes,
  dispatch,
  showCalendar,
  setShowCalendar,
}) {
  return (
    <FormGroup>
      <Label for="filter">
        <b>Which tasks</b>
      </Label>
      <CustomInput
        type="select"
        name={PARAMS.filter.name}
        id={PARAMS.filter.name}
        value={filter}
        onChange={(e) => {
          const alsoIncludes =
            PARAMS.filter.alsoIncludes[
              PARAMS.filter.values.indexOf(e.target.value)
            ];
          Object.keys(previousInclude).forEach((key) => {
            if (key in alsoIncludes) alsoIncludes[key] = previousInclude[key];
          });
          dispatch(setFilter(e.target.value));
          dispatch(setIncludes(alsoIncludes));
        }}
      >
        {PARAMS.filter.label.map((v) => (
          <option key={v} value={v.toLowerCase().replace(/\s/g, "")}>
            {v}
          </option>
        ))}
      </CustomInput>
      {!showCalendar ? (
        <span onClick={() => setShowCalendar(true)} className="small-action">
          Or choose date range
        </span>
      ) : (
        <span onClick={() => setShowCalendar(false)} className="small-action">
          Close calendar
        </span>
      )}
    </FormGroup>
  );
}
