import {HashLink} from "react-router-hash-link";
import {Card, CardImg, CardText, CardBody} from "reactstrap";
import {slugify} from "../../../utils/slugify";
import getStatusColor from "../../../utils/get-status-color";
import moment from "moment";
import {HOURS_LEFT} from "../../../constants";
import getImg from "../../../utils/get-img";

const FILTER_OUT = ["Brian Birkhauser", "Bryan Boettger"];

export default function EmployeeCard({
  employeeData,
  peopleData,
  role,
  tag,
  bizDays,
}) {
  const dayOfWeek = moment().day();
  const timeLeft = HOURS_LEFT[dayOfWeek];
  return (
    employeeData &&
    peopleData &&
    Object.keys(employeeData).map((name, index) => {
      if (
        employeeData[name].roles.includes(role) &&
        !FILTER_OUT.includes(name)
      ) {
        let img = getImg(employeeData, peopleData, name);
        let allHours;
        if (tag !== "all-tags" && employeeData[name].tagHours) {
          allHours = (employeeData[name].tagHours[tag] / 60).toFixed(2);
        } else {
          allHours = (employeeData[name].allHours / 60).toFixed(2);
        }
        const [firstName, lastName] = name.split(" ");
        return (
          img && (
            <div id={name} key={index}>
              <HashLink
                to={`/workload#${slugify(name)}`}
                style={{color: "black", textDecoration: "none"}}
              >
                <Card
                  style={{
                    backgroundColor: getStatusColor(
                      allHours,
                      bizDays * 8 || timeLeft
                    ),
                  }}
                  className="employee-card"
                >
                  <CardImg src={img} alt="Card image cap" />
                  <CardBody className="card-body">
                    <CardText className="card-all-hours">{allHours}</CardText>
                    <CardText className="card-all-projects">
                      All projects
                    </CardText>
                    <CardText className="card-all-internal-hours">
                      {(employeeData[name].internalHours / 60).toFixed(2)}
                    </CardText>
                    <CardText className="card-internal-hours">
                      Internal only
                    </CardText>
                    <CardText className="card-first-name">{firstName}</CardText>
                    <CardText className="card-last-name">{lastName}</CardText>
                  </CardBody>
                </Card>
              </HashLink>
            </div>
          )
        );
      }
      return null;
    })
  );
}
