const getImg = (projectsData, peopleData, name) => {
  let img = (projectsData[name] && projectsData[name].avatarUrl) || "";
  if (!img) {
    img = peopleData.find(
      (p) =>
        `${p["first-name"]} ${p["last-name"]}`.toLowerCase() ===
        name.toLowerCase()
    );
    img = img && img["avatar-url"];
  }
  return img;
};

export default getImg;
