import {
  setIsLoadingTeamworkData,
  setEmployeeData,
  setPeopleData,
  setPeopleError,
  setNumResults,
  setBizDays,
  setIsAuth,
  setToken,
  setTaskError,
} from "../actions";
import fetchAllPages from "../utils/fetch-all-pages";
import queryString from "query-string";
import { TAGS } from "../constants";
import moment from "moment-business-days";
import { slugify } from "../utils/slugify";

const days = {
  all: null,
  anytime: null,
  thisweek: null,
  overdue: null,
  nodate: null,
  nostartdate: null,
  completed: null,
  today: 1,
  tomorrow: 1,
  within7: 5,
  within14: 10,
  within30: 21,
  within365: 250,
};

function getTeamworkTasks(contributors = []) {
  return async (dispatch, state) => {
    if (!contributors.length) {
      return false;
    }
    const { userReducer, filtersReducer, teamworkDataReducer } = state();
    const { filter, include, ignoreStartDates, sort, startDate, endDate } =
      filtersReducer;

    let qpObj;
    if (startDate === null) {
      const bizDays = days[filter];
      dispatch(setBizDays(bizDays));
      qpObj = {
        filter,
        include: Object.keys(include)
          .filter((key) => include[key])
          .join(","),
        "ignore-start-dates": ignoreStartDates,
        sort,
      };
    } else {
      const bizDays = moment(startDate, "YYYYMMDD").businessDiff(
        moment(endDate, "YYYYMMDD")
      );
      dispatch(setBizDays(bizDays + 1));
      qpObj = {
        include: Object.keys(include)
          .filter((key) => include[key])
          .join(","),
        "ignore-start-dates": ignoreStartDates,
        sort,
        startDate,
        endDate,
      };
    }
    const qp = queryString.stringify(qpObj);
    dispatch(setIsLoadingTeamworkData(true));
    let response;
    let peopleData;
    if (teamworkDataReducer.peopleData.length === 0) {
      const qpPeopleObj = qpObj;
      qpPeopleObj.filter = "all";
      const qpPeople = queryString.stringify(qpPeopleObj);
      try {
        response = await fetchAllPages(
          "people.json",
          `?${qpPeople}&responsible-party-ids=-1&page=`,
          1,
          userReducer.token,
          "people"
        );
      } catch (e) {
        console.log(e);
        dispatch(setIsLoadingTeamworkData(false));
        dispatch(setPeopleError(e.message));
        return e;
      }
      dispatch(setPeopleData(response));
      peopleData = response;
    } else {
      peopleData = teamworkDataReducer.peopleData;
    }
    try {
      response = await fetchAllPages(
        "tasks.json",
        `?${qp}&responsible-party-ids=-1&page=`,
        1,
        userReducer.token,
        "todo-items"
      );
    } catch (e) {
      console.log(e);
      dispatch(setIsLoadingTeamworkData(false));
      dispatch(setIsAuth(false));
      dispatch(setToken(""));
      dispatch(setTaskError(e.message));
      return e;
    }
    const tasksWithEstimates = response.filter(
      (task) => !!task["estimated-minutes"]
    );
    dispatch(setNumResults(tasksWithEstimates.length));
    const employeeData = tasksWithEstimates.reduce((acc, curr) => {
      const key = curr["responsible-party-names"];
      const creatorKey = `${curr["creator-firstname"]} ${curr["creator-lastname"]}`;
      if (key in acc) {
        const internalHours =
          curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0;
        acc[key].data.push(curr);
        acc[key].allHours += curr["estimated-minutes"] || 0;
        acc[key].internalHours += internalHours;
        if (curr.tags) {
          curr.tags.forEach(
            (t) =>
              (acc[key].tagHours[slugify(t.name)] +=
                curr["estimated-minutes"] || 0)
          );
        }
      } else {
        const currEmployee = contributors.find(
          (e) =>
            !!key &&
            `${e["first_name"]} ${e["last_name"]}`.toLowerCase() ===
              key.toLowerCase()
        );
        const roles = currEmployee ? currEmployee.roles : [];
        const tagHours = TAGS.reduce(
          (o, c) => ({
            ...o,
            [slugify(c)]:
              (curr.tags &&
                curr.tags.includes(c) &&
                curr["estimated-minutes"]) ||
              0,
          }),
          {}
        );
        acc[key] = {
          data: [curr],
          avatarUrl: "",
          allHours: curr["estimated-minutes"] || 0,
          internalHours:
            curr["company-id"] === 62367 ? curr["estimated-minutes"] : 0,
          roles: roles,
          tagHours: tagHours,
        };
      }
      if (creatorKey in acc) {
        acc[creatorKey]["avatarUrl"] = curr["creator-avatar-url"];
      }
      return acc;
    }, {});
    const employeeDataKeys =
      Object.keys(employeeData).map((key) => key.toLowerCase()) || [];
    contributors.forEach((e) => {
      let avatar;
      const name = `${e["first_name"]} ${e["last_name"]}`;
      const nameLower = name.toLowerCase();
      const roles = e["roles"];
      const hasWorkloadRole = roles.includes("all");
      if (
        !employeeDataKeys.some((key) => key === nameLower) &&
        hasWorkloadRole
      ) {
        avatar = peopleData.find((p) => {
          const peopleName = `${p["first-name"]} ${p["last-name"]}`;
          if (nameLower === peopleName.toLowerCase()) {
            return p["avatar-url"];
          }
          return null;
        });
        employeeData[name] = {
          allHours: 0,
          internalHours: 0,
          data: [],
          roles: e["roles"],
          avatarUrl: avatar ? avatar["avatar-url"] : "",
        };
      }
    });
    const sortedEmployeeData = {};
    Object.keys(employeeData)
      .sort()
      .forEach(function (key) {
        sortedEmployeeData[key] = employeeData[key];
      });
    dispatch(setEmployeeData(sortedEmployeeData));
    dispatch(setIsLoadingTeamworkData(false));
  };
}

export default getTeamworkTasks;
